@use 'sass:math';

@import 'styles/mixins';
@import 'styles/variables';

.field {
  display: block;
  margin-bottom: math.div(12, 16) * 1rem;
  position: relative;
}

.labelContainer {
  @media (min-width: 1500px) {
    margin-top: 0;
    display: inline-block;
    width: 30%;
  }
}
