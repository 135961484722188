@import 'styles/variables';
@import 'styles/mixins';

.crossAppAlert,
.expanded {
  line-height: inherit;
  position: fixed;
    top: 0;
    right: 0;
    left: 0;
  transition: .6s;
  text-align: center;
}

.crossAppAlert {
  z-index: 2;
}

.inner {
  border: 1px solid transparent;
  box-sizing: content-box;
  font-weight: 500;
  line-height: inherit;
  max-height: 53px;
  padding: 13px 20px 15px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  @media (max-width: 767px) {
    padding: 6px 10px;
  }

  > * {
    vertical-align: middle;
  }
}

.title,
.expanded__title {
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  margin: 0 .3rem 0 0;

  svg {
    margin-right: .6em;
    position: relative;
    top: -3px;
    vertical-align: middle;
  }
}

.title {
  font-size: .85rem;

  @media (max-width: 767px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.more,
.dismiss,
.expanded__dismiss {
  -webkit-appearance: none;
  background: transparent;
  border: none;
}

.more__short {
  display: inline-block;

  @media (min-width: 768px) {
    display: none;
  }
}

.more__full {
  display: inline-block;

  @media (max-width: 767px) {
    display: none;
  }
}

.dismiss {
  background-repeat: no-repeat;
  background-size: 16px 16px;
  color: inherit;
  font-size: 0;
  height: 20px;
  position: absolute;
  width: 20px;

  @media (min-width: 768px) {
    right: .7rem;
    top: .9rem;
  }

  @media (max-width: 767px) {
    top: .45rem;
    right: .45rem;
  }

  @media (max-width: 420px) {
    position: static;
  }

  svg {
    height: .75rem;
    width: .75rem;
  }
}

.expanded__header {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 1rem;
  width: 100%;
}

.expanded__dismiss {
  color: inherit;
  font-size: 0;
  opacity: .4;
  position: relative;
  right: -24px;

  @media (max-width: 767px) {
    right: -0.2rem;
  }

  svg {
    height: 1.45rem;
    width: 1.45rem;
  }
}

.more {
  color: $color-primary;
  font-size: .85rem;
  line-height: 1.35;
  padding: 0 6px;

  .inner[data-severity="red"] & {
    color: #fff;

    &:hover {
      color: #fff;
    }

    span {
      text-decoration: underline;
    }

    &:hover span {
      text-decoration: none;
    }
  }
}

.inner[data-severity="custom"],
.expanded__inner[data-severity="custom"] {
  color: #fff;
  background-color: #0069E2;
}

.expanded__inner[data-severity="custom"] {
  border: 1px dashed #0069E2;
}

.inner[data-severity="custom"] .title a {
  color: #fff !important;
  text-decoration: underline !important;
}

.inner[data-severity="green"],
.expanded__inner[data-severity="green"] {
  color: #166534;
  background-color: #f0fdf4;
}

.expanded__inner[data-severity="green"] {
  border: 1px dashed #16a34a;
}

.inner:not(data-severity),
.expanded__inner:not(data-severity),
.inner[data-severity="info"],
.expanded__inner[data-severity="info"] {
  color: #1e40af;
  background-color: #eff6ff;
}

.expanded__inner:not(data-severity),
.expanded__inner[data-severity="info"] {
  border: 1px dashed #2563eb;
}

.inner[data-severity="red"] {
  background-color: #FB7185; // rose-400
  color: #fff;
  font-weight: 600;
}

.expanded__inner[data-severity="red"] {
  background-color: #fef2f2;
  color: #991b1b;
}

.expanded__inner[data-severity="red"] {
  border: 1px dashed #dc2626;
}

.inner[data-severity="yellow"],
.expanded__inner[data-severity="yellow"] {
  background-color: #fffbeb;
  color: #92400e;
}

.expanded__inner[data-severity="yellow"] {
  border: 1px dashed #d97706;
}

.expanded {
  background: rgba(#000,.3);
  align-items: center;
  display: flex;
  height: 100vh;
  opacity: 0;
  justify-content: center;
  transition: opacity(.5);

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  &[data-show="show"] {
    z-index: 999;
    opacity: 1;
  }
}

.expanded__inner {
  align-items: flex-start;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  justify-content: safe flex-start;
  margin-left: auto;
  margin-right: auto;
  max-height: 80vh;
  max-width: 760px;
  line-height: inherit;
  overflow-y: auto;
  padding: 36px 48px;
  text-align: left;
  transition: .6s;

  position: fixed;
  right: 10vw;
  left: 10vw;
  z-index: 1;

  @media (max-width: 767px) {
    padding: 20px 16px;
    top: 5vw;
    right: 5vw;
    bottom: 5vw;
    left: 5vw;
  }
}

.expanded__title {
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 1.1rem;
  }
}

.expanded__description {
  margin: 0 auto .4rem;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  width: 100%;
}
