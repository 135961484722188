@import 'driver.js/dist/driver.css';

.onboarding-tour {
  padding: 20px 18px 18px;
  max-width: 350px;
  border-radius: 10px;

  * {
    font-family: inherit;
  }
}

.driver-active #root > div > div > aside {
  display: none;
}

.driver-popover-close-btn {
  display: block !important;
  height: 32px !important;
}

.driver-overlay {
  pointer-events: none !important;
}

.onboarding__bot-icon {
  font-size: 14px;
  border: 2px solid white;
  border-radius: 50%;
  position: absolute;
  width: 2.5em;
  height: 2.5em;
  left: -1em;
  top: -1em;
  box-shadow: 0 0 16px -6px rgba(0, 0, 0, 0.5);
  opacity: 0;
  animation: bot-popup 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards 0.175s;
}

@keyframes bot-popup {
  0% {
    transform: scale(0.75);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.driver-popover-title {
  font-family: $font-family;
}

.driver-popover-description {
  font-family: $font-family;
  font-weight: 500;
  color: $color-neutral-darker;
}

.driver-popover-navigation-btns button + button {
  margin-left: 12px;
}

.driver-popover-navigation-btns button {
  font-family: $font-family;
  font-weight: 500;

  background: $color-primary;
  border: 1px solid transparent;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 0.725rem;
  font-weight: 600;
  line-height: 1.3;
  min-width: 6em;
  padding: 0.5em 1.5em;
  text-decoration: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition: 225ms;
  text-shadow: none;
  text-align: center;

  &:hover,
  &:focus {
    background: $color-primary;
    outline: 2px solid $color-primary-dark;
    outline-offset: 2px;
  }

  &:active {
    background: $color-primary-darker;
  }

  &.driver-popover-prev-btn {
    background: $color-neutral-lighter;
    color: $color-neutral-darker;

    &:hover,
    &:focus {
      background: $color-neutral-light;
      color: $color-neutral-darker;
      outline: 2px solid $color-neutral-light;
    }

    &:active {
      background: $color-neutral-light;
    }
  }
}

@media (max-width: 768px) {
  .onboarding-tour {
    max-width: 325px;
  }

  .onboarding__bot-icon {
    font-size: 12px;
  }
}
