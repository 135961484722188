@tailwind base;
@tailwind components;
@tailwind utilities;

$font-family:
  'Figtree',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Helvetica,
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol';

@import 'styles/variables';
@import 'styles/forms';
@import 'styles/typography';
@import 'styles/onboarding-tour';

html {
  box-sizing: border-box;
  font-family: $font-family;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: $color-neutral-darker;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  background: $color-neutral-lighter;
  border: none;
  height: 1px;
  margin: 2 * $spacing-unit 0;
}

figure {
  margin: 0;
  padding: 0;
}

input,
textarea,
select,
button {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

svg {
  display: inline-block;
  height: 1em;
  width: 1em;
}

@media (max-width: 1024px) {
  [data-hide-on-tablet] {
    display: none;
  }
}

@media (max-width: 767px) {
  [data-hide-on-mobile] {
    display: none;
  }
}

@media (max-width: 440px) {
  .hidden\:440 {
    display: none;
  }
}

#root {
  max-width: 100%;
  overflow-x: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

summary::-webkit-details-marker {
  display: none;
}
